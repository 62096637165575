import React, { useEffect, useState } from "react";
import ReactWhatsapp from "react-whatsapp";
import FooterAR from "./FooterAR";
import { useNavigate } from "react-router-dom";
import HeaderAR from "./HeaderAR";
function ReservationAR() {
  const navigate = useNavigate();
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [msg, setmsg] = useState("");
  const [subject, setsubject] = useState("");
  const [nbr, setnbr] = useState("");
  const [phone, setphone] = useState("");
  const [time, settime] = useState("");
  const [date, setdate] = useState("");
  const data = {
    name: name,
    email: email,
    phone: phone,
    date: date,
    time: time,
    msg: msg,
    nbr: nbr,
  };
  const sendmessage = () => {
    alert("réseravation envoyée");
  };
  return (
    <>
      <HeaderAR></HeaderAR>

      <section id="chefs" class="chefs">
        <div class="container" data-aos="fade-up">
          <div
            class="section-title"
            style={{ color: "#cda45e", float: "right" }}
          >
            <h2 style={{ color: "#cda45e", float: "right" }}>حجز</h2>
            <p>عبر تطبيق الواتساب</p>
          </div>

          <div class="row" style={{ color: "#cda45e", float: "right" }}>
            <form
              // method="post"
              role="form"
              class="php-email-form"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <div
                class="row"
                style={{ color: "#cda45e", float: "inline-end" }}
              >
                <div class="col-lg-4 col-md-6 form-group justify-content-end">
                  <input
                    type="text"
                    name="name"
                    class="form-control"
                    id="name"
                    value={name}
                    onChange={(e) => setname(e.target.value)}
                    placeholder="اسم"
                    data-rule="minlen:4"
                    data-msg="Please enter at least 4 chars"
                  />
                  <div class="validate"></div>
                </div>
                <div class="col-lg-4 col-md-6 form-group mt-3 mt-md-0">
                  <input
                    type="email"
                    class="form-control"
                    name="email"
                    id="email"
                    value={email}
                    onChange={(e) => setemail(e.target.value)}
                    placeholder="البريد الإلكتروني"
                    data-rule="email"
                    data-msg="Please enter a valid email"
                  />
                  <div class="validate"></div>
                </div>
                <div class="col-lg-4 col-md-6 form-group mt-3 mt-md-0">
                  <input
                    type="text"
                    class="form-control"
                    name="phone"
                    id="phone"
                    value={phone}
                    onChange={(e) => setphone(e.target.value)}
                    placeholder="رقم الهاتف"
                    data-rule="minlen:4"
                    data-msg="Please enter at least 4 chars"
                  />
                  <div class="validate"></div>
                </div>
                <div class="col-lg-4 col-md-6 form-group mt-3">
                  <input
                    type="date"
                    name="date"
                    class="form-control"
                    id="date"
                    value={date}
                    onChange={(e) => setdate(e.target.value)}
                    placeholder="Date"
                    data-rule="minlen:4"
                    data-msg="Please enter at least 4 chars"
                  />
                  <div class="validate"></div>
                </div>
                <div class="col-lg-4 col-md-6 form-group mt-3">
                  <input
                    type="time"
                    class="form-control"
                    name="time"
                    id="time"
                    value={time}
                    onChange={(e) => settime(e.target.value)}
                    placeholder="الوقت"
                    data-rule="minlen:4"
                    data-msg="Please enter at least 4 chars"
                  />
                  <div class="validate"></div>
                </div>
                <div class="col-lg-4 col-md-6 form-group mt-3">
                  <input
                    type="number"
                    class="form-control"
                    name="people"
                    id="people"
                    value={nbr}
                    onChange={(e) => setnbr(e.target.value)}
                    placeholder="عدد الأشخاص"
                    data-rule="minlen:1"
                    data-msg="Please enter at least 1 chars"
                  />
                  <div class="validate"></div>
                </div>
            
              <div class="col-lg-12 col-md-6 form-group mt-3">
                <input
                  class="form-control"
                  name="message"
                  rows="1"
                  value={msg}
                  onChange={(e) => setmsg(e.target.value)}
                  placeholder="رسالة"
                ></input>
                <div class="validate"></div>
              </div>
              </div>
              <p></p>
              <div class="text-center">
                <div class="col-lg-12">
                  {/* <div class="col-lg-4 col-xs-3 offset-3"> */}
                  <ReactWhatsapp
                    class="bi bi-whatsapp"
                    type="submit"
                    style={{
                      backgroundColor: "#cda45e",

                      width: 80,
                      height: 45,
                      borderRadius: 25,
                    }}
                    number="216-26-26-4616"
                    onClick={(e) => sendmessage(e)}
                    message={
                      " réservation pour  client:  " +
                      data.name +
                      "  email:  " +
                      data.email +
                      "  phone:  " +
                      data.phone +
                      "  date:  " +
                      data.date +
                      "  time:  " +
                      data.time +
                      "  nombre de personne:  " +
                      data.nbr +
                      "  message:  " +
                      data.msg
                    }
                  />
                  {/* </div> */}
                </div>
                <br></br>
              </div>
            </form>
          </div>
        </div>
      </section>
      <FooterAR></FooterAR>
    </>
  );
}

export default ReservationAR;
