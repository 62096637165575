import React, { useEffect, useState } from "react";
import Footer from "./Footer";
import Header from "./Header";
import AliceCarousel from "react-alice-carousel";

import "react-alice-carousel/lib/alice-carousel.css";
import { useNavigate } from "react-router-dom";

function Gallery() {
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);
  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);
  const navigate = useNavigate();

  // Thumbnail image controls

  return (
    <>
      <Header></Header>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <section id="gallery" class="gallery">
        <div class="container" data-aos="fade-up">
          <div class="section-title">
            <h2>Galerie</h2>
            <p>Quelques photos de notre Restaurant</p>
          </div>
        </div>
        <br></br>

        <div class="container-fluid" data-aos="fade-up" data-aos-delay="100">
          <div class="row g-0">
            <div class="slideshow-container">
              <div class="mySlides">
                <AliceCarousel>
                  <div class="col-lg-8  offset-1">
                    {" "}
                    <img
                      alt="cuisine turque "
                      title="turkish food"
                      src="assets/img/imgComp/IMG_0303.gif"
                      class="img-fluid"
                      style={{ width: 650 }}
                    />
                  </div>
                  <div class="col-lg-8 offset-1">
                    {" "}
                    <img
                      alt="cuisine turque "
                      title="turkish food"
                      src="assets/img/imgComp/IMG_4577.gif"
                      class="img-fluid"
                      style={{ width: 650 }}
                    />
                  </div>
                  <div class="col-lg-8 offset-1">
                    {" "}
                    <img
                      alt="cuisine turque "
                      title="turkish food"
                      src="assets/img/imgComp/IMG_0299.gif"
                      class="img-fluid"
                      style={{ width: 650 }}
                    />
                  </div>
                  <div class="col-lg-7 offset-1">
                    {" "}
                    <img
                      alt="cuisine turque "
                      title="turkish food"
                      src="assets/img/imgComp/IMG_4454.gif"
                      class="img-fluid"
                      style={{ width: 650 }}
                    />
                  </div>

                  <div class="col-lg-7 offset-1">
                    {" "}
                    <img
                      alt="cuisine turque "
                      title="turkish food"
                      src="assets/img/imgComp/IMG_0115.gif"
                      class="img-fluid"
                      style={{ width: 650 }}
                    />
                  </div>
                  <div class="col-lg-7 offset-1">
                    {" "}
                    <img
                      alt="cuisine turque "
                      title="turkish food"
                      src="assets/img/imgComp/IMG_0023.gif"
                      class="img-fluid"
                      style={{ width: 650 }}
                    />
                  </div>
                  <div class="col-lg-7 offset-1">
                    {" "}
                    <img
                      alt="cuisine turque "
                      title="turkish food"
                      src="assets/img/imgComp/IMG_4441.gif"
                      class="img-fluid"
                      style={{ width: 650 }}
                    />
                  </div>
                  <div class="col-lg-7 offset-1">
                    {" "}
                    <img
                      alt="cuisine turque "
                      title="turkish food"
                      src="assets/img/imgComp/IMG_4205.gif"
                      class="img-fluid"
                      style={{ width: 650 }}
                    />
                  </div>

                  <div class="col-lg-7 offset-1">
                    {" "}
                    <img
                      alt="cuisine turque "
                      title="turkish food"
                      src="assets/img/imgComp/IMG_0003.gif"
                      class="img-fluid"
                      style={{ width: 650 }}
                    />
                  </div>
                  <div class="col-lg-7 offset-1">
                    {" "}
                    <img
                      alt="cuisine turque "
                      title="turkish food"
                      src="assets/img/imgComp/IMG_0017.gif"
                      class="img-fluid"
                      style={{ width: 650 }}
                    />
                  </div>
                  <div class="col-lg-7 offset-1">
                    {" "}
                    <img
                      alt="cuisine turque "
                      title="turkish food"
                      src="assets/img/imgComp/IMG_0266.gif"
                      class="img-fluid"
                      style={{ width: 650 }}
                    />
                  </div>
                </AliceCarousel>
              </div>
            </div>
            <br></br>
            <br></br>
            <div class="col-lg-3 col-md-4">
              <div class="gallery-item">
                <img
                  src="assets/img/imgComp/IMG_0001.gif"
                  alt="cuisine turque "
                  title="turkish food"
                  class="img-fluid"
                />
              </div>
            </div>

            <div class="col-lg-3 col-md-4">
              <div class="gallery-item">
                <img
                  src="assets/img/imgComp/IMG_0279.gif"
                  alt="cuisine turque "
                  title="turkish food"
                  class="img-fluid"
                />
              </div>
            </div>

            <div class="col-lg-3 col-md-4">
              <div class="gallery-item">
                <img
                  src="assets/img/imgComp/IMG_0088.gif"
                  alt="cuisine turque "
                  title="turkish food"
                  class="img-fluid"
                />
              </div>
            </div>

            <div class="col-lg-3 col-md-4">
              <div class="gallery-item">
                <img
                  src="assets/img/imgComp/IMG_0055.gif"
                  alt="cuisine turque "
                  title="turkish food"
                  class="img-fluid"
                />
              </div>
            </div>

            <div class="col-lg-3 col-md-4">
              <div class="gallery-item">
                <img
                  src="assets/img/imgComp/IMG_0278.gif"
                  alt="cuisine turque "
                  title="turkish food"
                  class="img-fluid"
                />
              </div>
            </div>

            <div class="col-lg-3 col-md-4">
              <div class="gallery-item">
                <img
                  src="assets/img/imgComp/IMG_0279.gif"
                  alt="cuisine turque "
                  title="turkish food"
                  class="img-fluid"
                />
              </div>
            </div>

            <div class="col-lg-3 col-md-4">
              <div class="gallery-item">
                <img
                  src="assets/img/imgComp/IMG_0290.gif"
                  alt="cuisine turque "
                  title="turkish food"
                  class="img-fluid"
                />
              </div>
            </div>

            <div class="col-lg-3 col-md-4">
              <div class="gallery-item">
                <img
                  src="assets/img/imgComp/IMG_4361.gif"
                  alt="cuisine turque "
                  title="turkish food"
                  class="img-fluid"
                />
              </div>
            </div>
            <div class="col-lg-3 col-md-4">
              <div class="gallery-item">
                <img
                  src="assets/img/imgComp/IMG_0283.gif"
                  alt="cuisine turque "
                  title="turkish food"
                  class="img-fluid"
                />
              </div>
            </div>

            <div class="col-lg-3 col-md-4">
              <div class="gallery-item">
                <img
                  src="assets/img/imgComp/IMG_0301.gif"
                  alt="cuisine turque "
                  title="turkish food"
                  class="img-fluid"
                />
              </div>
            </div>
            <div class="col-lg-3 col-md-4">
              <div class="gallery-item">
                <img
                  src="assets/img/imgComp/IMG_0308.gif"
                  alt="cuisine turque "
                  title="turkish food"
                  class="img-fluid"
                />
              </div>
            </div>
            <div class="col-lg-3 col-md-4">
              <div class="gallery-item">
                <img
                  src="assets/img/imgComp/IMG_4308.gif"
                  alt="cuisine turque "
                  title="turkish food"
                  class="img-fluid"
                />
              </div>
            </div>

            <div class="col-lg-3 col-md-4">
              <div class="gallery-item">
                <img
                  src="assets/img/imgComp/IMG_4583.gif"
                  alt="cuisine turque "
                  title="turkish food"
                  class="img-fluid"
                />
              </div>
            </div>

            <div class="col-lg-3 col-md-4">
              <div class="gallery-item">
                <img
                  src="assets/img/imgComp/IMG_4566.gif"
                  alt="cuisine turque "
                  title="turkish food"
                  class="img-fluid"
                />
              </div>
            </div>

            <div class="col-lg-3 col-md-4">
              <div class="gallery-item">
                <img
                  src="assets/img/imgComp/IMG_4469.gif"
                  alt="cuisine turque "
                  title="turkish food"
                  class="img-fluid"
                />
              </div>
            </div>

            <div class="col-lg-3 col-md-4">
              <div class="gallery-item">
                <img
                  src="assets/img/imgComp/IMG_0124.gif"
                  alt="cuisine turque "
                  title="turkish food"
                  class="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer></Footer>
    </>
  );
}
export default Gallery;
