import React, { useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
function About() {
  return (
    <>
    <Header></Header>
    <br></br><br></br><br></br><br></br><br></br>
      <section id="about" class="about">
        <div class="container" data-aos="fade-up">
          <div class="row">
            <div
              class="col-lg-6 order-1 order-lg-2"
              data-aos="zoom-in"
              data-aos-delay="100"
            >
              <div class="about-img">
                <img src="assets/img/pfeS/s42.jpg" alt="" />
              </div>
            </div>
            <div class="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content">
              <h3>SARAY Turkish Cuisine vous offre un voyage culinaire,</h3>
              <p class="fst-italic">
                Venez déguster nos spécialités turques concoctées par notre chef
                istanbuliote.
              </p>
              <ul>
                <li>
                  <i class="bi bi-check-circle"></i> Grâce à Saray, Venez
                  déguster des plats gourmands servis avec ses entrées
                  fraichement préparées
                </li>
                <li>
                  <i class="bi bi-check-circle"></i>Grâce à Saray, Venez
                  déguster des plats gourmands servis avec ses entrées
                  fraichement préparées
                </li>
                <li>
                  <i class="bi bi-check-circle"></i> Grâce à Saray, Venez
                  déguster des plats gourmands servis avec ses entrées
                  fraichement préparées
                </li>
              </ul>
              <p>
                Nous sommes ouverts tous les jours de la semaine de 08h jusqu'à
                23h et les weekends de 9h jusqu'à minuit SARAY Turkish Cuisine
                vous offre un voyage culinaire, venez déguster nos spécialités
                turques concoctées par notre chef istanbuliote,
              </p>
            </div>
          </div>
        </div>
      </section>
      <Footer></Footer>
    </>
  );
}

export default About;
