import React, { useEffect, useState } from "react";
import Footer from "./Footer";
import Header from "./Header";

import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
//import data from "./data.json";
import data from "./datafrens.json";

import "react-medium-image-zoom/dist/styles.css";
function MenuFR() {
  const [lgarabe, setlgarabe] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState(data);
  const [selectedValue, setSelectedValue] = useState("");

  useEffect(() => {
    setFilteredData(data);
  }, []);
  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
    if (event.target.value == "Entrées") {
      alert("ok entre");
      handleInputChangeEntré();
    }
    if (event.target.value == "Sandwichs") {
    
      handleInputChangeSandwitch();
    }
    if (event.target.value == "Salades") {
     
      handleInputChangeSalade();
    }
    if (event.target.value == "PİDE & LAHMACUN") {
     
      handleInputChangepide();
    }
    if (event.target.value == "PLATS") {
     
      handleInputChangeWajba();
    }
    if (event.target.value == "Plats grillés") {
     
      handleInputChangegratin();
    }
    if (event.target.value == "Plats spéciaux") {
     
      handleInputChangePlatspecials();
    }
    if (event.target.value == "Supplément") {
     
      handleInputChangesupplement();
    }     
    if (event.target.value == "Desserts") {
     
      handleInputChangeSucré();
    }     
    
    if (event.target.value == "Boissons froides") {
     
      handleInputChangefroid();
    }     
    if (event.target.value == "Boissons chaudes") {
     
      handleInputChangechaud();
    }  


              
  };
  const handleInputChange = (event) => {
    const { value } = event.target;
   // setSearchTerm(value);
   setSearchTerm(event.target.value.toLowerCase())
    filterData(value);
  };

  const filterData = (searchTerm) => {
    const filteredData = data.filter((item) =>
    //  item.data.name &&  item.data.name.toLowerCase().includes(searchTerm.toLowerCase())
   item.data.name && item.data.name.toLowerCase().includes(searchTerm)
    );
    setFilteredData(filteredData);
  };

  const handleInputChangeEntré = (event) => {
    const { value } = "Entrées";
    setSearchTerm(value);
    filterDataentré(value);
  };
  const filterDataentré = (searchTerm) => {
    const filtered = data.filter((item) => item.category == "Entrées");
    setFilteredData(filtered);
  };
  const handleInputChangeSalade = (event) => {
    const { value } = "Salades";
    setSearchTerm(value);
    filterDatasalade(value);
  };
  const filterDatasalade = (searchTerm) => {
    const filtered = data.filter((item) => item.category == "Salades");
    setFilteredData(filtered);
  };
  const handleInputChangeSandwitch = (event) => {
    const { value } = "Sandwichs";
    //setSearchTerm(value);
    filterDatasandwitch(value);
  };
  const filterDatasandwitch = (searchTerm) => {
    const filtered = data.filter((item) => item.category == "Sandwichs");
    setFilteredData(filtered);
  };
  const handleInputChangeSucré = (event) => {
    const { value } = "Desserts";
    setSearchTerm(value);
    filterDatasucré(value);
  };
  const filterDatasucré = (searchTerm) => {
    const filtered = data.filter((item) => item.category == "Desserts");
    setFilteredData(filtered);
  };

  const handleInputChangeWajba = (event) => {
    const { value } = "PLATS";
    setSearchTerm(value);
    filterDatawajba(value);
  };
  const filterDatawajba = (searchTerm) => {
    const filtered = data.filter((item) => item.category == "PLATS");
    setFilteredData(filtered);
  };

  const handleInputChangepide = (event) => {
    const { value } = "PİDE & LAHMACUN";
    setSearchTerm(value);
    filterDatapide(value);
  };
  const filterDatapide = (searchTerm) => {
    const filtered = data.filter((item) => item.category == "PİDE & LAHMACUN");
    setFilteredData(filtered);
  };

  const handleInputChangegratin = (event) => {
    const { value } = "Plats grillés";
    setSearchTerm(value);
    filterDatagratin(value);
  };
  const filterDatagratin = (searchTerm) => {
    const filtered = data.filter((item) => item.category == "Plats grillés");
    setFilteredData(filtered);
  };

  const handleInputChangesupplement = (event) => {
    const { value } = "Supplément";
    setSearchTerm(value);
    filterDatasupplement(value);
  };
  const filterDatasupplement = (searchTerm) => {
    const filtered = data.filter((item) => item.category == "Supplément");
    setFilteredData(filtered);
  };

  const handleInputChangepain = (event) => {
    const { value } = "Pains";
    setSearchTerm(value);
    filterDatapain(value);
  };
  const filterDatapain = (searchTerm) => {
    const filtered = data.filter((item) => item.category == "Pains");
    setFilteredData(filtered);
  };

  const handleInputChangechaud = (event) => {
    const { value } = "Boissons chaudes";
    setSearchTerm(value);
    filterDatachaud(value);
  };
  const filterDatachaud = (searchTerm) => {
    const filtered = data.filter((item) => item.category == "Boissons chaudes");
    setFilteredData(filtered);
  };

  const handleInputChangefroid = (event) => {
    const { value } = "Boissons froides";
    setSearchTerm(value);
    filterDatafroid(value);
  };
  const filterDatafroid = (searchTerm) => {
    const filtered = data.filter((item) => item.category == "Boissons froides");
    setFilteredData(filtered);
  };

  const handleInputChangePlatspecials = (event) => {
    const { value } = "Plats spéciaux";
    setSearchTerm(value);
    filterDataspecial(value);
  };
  const filterDataspecial = (searchTerm) => {
    const filtered = data.filter((item) => item.category == "Plats spéciaux");
    setFilteredData(filtered);
  };

  const handleInputChangeTous = (event) => {
    setFilteredData(data);
  };
  const changelangue = (e) => {
    if (lgarabe) {
      setlgarabe(false);
    } else if (lgarabe == false) {
      setlgarabe(true);
    }
  };
  return (
    <>
      <Header></Header>

      <br></br>
      <br></br>

      <section id="menu" class="menu section-bg">
        <div class="container" data-aos="fade-up">
          {/* <section id="gallery" class="gallery">
            <div class="container" data-aos="fade-up">
              <div class="section-title">
                <p>Notre Menu</p>
              </div>
            </div>
          </section> */}
          <div class="row" data-aos="fade-up" data-aos-delay="100">
            <div class="section-title">
                <p>Notre Menu</p>
              </div>
            <div class="col-lg-12 d-flex justify-content-center">
            { /* <div class="col-lg-6">
                <input
                  type="text"
                  placeholder="Search..."
                  value={searchTerm}
                  onChange={handleInputChange}
                />
              </div>*/}
              <div class="col-lg-6">
                <select value={selectedValue} onChange={handleSelectChange}>
                  <option value="">Choisir </option>
                  <option value="Entrées">Entrées</option>
                  <option value="Salades">Salades</option>
                  <option value="Sandwichs">Sandwichs</option>
                  <option value="PİDE & LAHMACUN"> PİDE & LAHMACUN</option>
                  <option value="PLATS">PLATS</option>
                  <option value="Plats grillés"> Plats grillés</option>
                  <option value="Plats spéciaux">Plats spéciaux</option>

                  <option value="Supplément">Supplément</option>
                  <option value="Desserts"> Desserts</option>
                  <option value="Boissons froides">Boissons froides</option>
                  <option value="Boissons chaudes">Boissons chaudes</option>
                </select>
              </div>
            </div>
          </div>

        
          {/* <div class="row justify-content-center">
            <table class="col-lg-11 offset-1">
              <tr>
                <td class="col-lg-2" onClick={(e) => handleInputChangeTous(e)}>
                  <a class=" btn-book animated fadeInUp scrollto">Tous</a>
                </td>{" "}
                <td></td>
                <td></td>
                <td class="col-lg-2" onClick={(e) => handleInputChangeEntré(e)}>
                  <a class=" btn-book animated fadeInUp scrollto">Entrées</a>
                </td>{" "}
                <td></td>
                <td></td>
                <td
                  class="col-lg-2"
                  onClick={(e) => handleInputChangeSalade(e)}
                >
                  <a class=" btn-book animated fadeInUp scrollto">Salades</a>
                </td>{" "}
                <td></td>
                <td></td>
                <td
                  class="col-lg-2"
                  onClick={(e) => handleInputChangeSandwitch(e)}
                >
                  <a class=" btn-book animated fadeInUp scrollto">Sandwichs</a>
                </td>{" "}
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td class="col-lg-2" onClick={(e) => handleInputChangepain(e)}>
                  <a class=" btn-book animated fadeInUp scrollto">Pains</a>
                </td>{" "}
                <td></td>
                <td></td>
                <td class="col-lg-2" onClick={(e) => handleInputChangepide(e)}>
                  <a class=" btn-book animated fadeInUp scrollto">
                    PİDE & LAHMACUN
                  </a>
                </td>{" "}
                <td></td>
                <td></td>
                <td
                  class="col-lg-2 "
                  onClick={(e) => handleInputChangeWajba(e)}
                >
                  <a class=" btn-book animated fadeInUp scrollto">PLATS</a>
                </td>{" "}
                <td></td>
                <td></td>
                <td
                  class="col-lg-2"
                  onClick={(e) => handleInputChangegratin(e)}
                >
                  {" "}
                  <a class=" btn-book animated fadeInUp scrollto">
                    Plats grillés
                  </a>
                </td>{" "}
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td
                  class="col-lg-2 "
                  onClick={(e) => handleInputChangePlatspecials(e)}
                >
                  <a class=" btn-book animated fadeInUp scrollto">
                    Plats spéciaux
                  </a>
                </td>{" "}
                <td></td>
                <td></td>
                <td
                  class="col-lg-2"
                  onClick={(e) => handleInputChangesupplement(e)}
                >
                  <a class=" btn-book animated fadeInUp scrollto">Supplément</a>
                </td>{" "}
                <td></td>
                <td></td>
                <td class="col-lg-2" onClick={(e) => handleInputChangeSucré(e)}>
                  {" "}
                  <a class=" btn-book animated fadeInUp scrollto">Desserts</a>
                </td>{" "}
                <td></td>
                <td></td>
                <td class="col-lg-2" onClick={(e) => handleInputChangefroid(e)}>
                  <a class=" btn-book animated fadeInUp scrollto">
                    Boissons froides
                  </a>
                </td>{" "}
                <td class="col-lg-2" onClick={(e) => handleInputChangechaud(e)}>
                  <a class=" btn-book animated fadeInUp scrollto">
                    Boissons chaudes
                  </a>
                </td>{" "}
              </tr>
            </table>
          </div> */}
        </div>

        {filteredData.map((item) => (
          <div
            key={item.id}
            class="row menu-container"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            <div class="row justify-content-center menu-item filter-starters">
              <Zoom>
                <div class="row justify-content-center">
                  <a class="row justify-content-center" href="#"></a>
                  <img
                    src={item.img}
                    class="menu-img img-fluid"
                    alt="cuisine turque "
                    title="turkish food"
                    width={50}
                  />
                </div>
              </Zoom>

              <div class="col-lg-12 justify-content-center  menu-content">
                <p>
                  <h1 style={{ color: "#cda45e" }}> {item.category}</h1>
                  <ul>
                    {Array.isArray(item.data) &&
                      item.data.map((item2, innerIndex) => (
                        <a href="#">
                          <li key={innerIndex}>
                            <a href="#">
                              {" "}
                              {item2.name} .............{" "}
                              <span>{item2.price} TND</span>
                            </a>
                          </li>
                        </a>
                      ))}
                  </ul>
                </p>

                {/* <span>{item.price}</span> */}
              </div>
            </div>
          </div>
        ))}
      </section>
      {/****************fin test last
      <section id="menu" class="menu section-bg">
        <div class="container" data-aos="fade-up">
          <div class="section-title">
            <h2>Menu</h2>
            &nbsp; &nbsp;<p>Consulter notre Menu</p>
            <br></br>
            <div className="col-lg-2 col-md-2 col-xs-3">
              <a
                class="book-a-table-btn scrollto "
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  alignContent: "center",
                }}
                onClick={(e) => changelangue(e)}
              >
                Arabe/Turc
              </a>
            </div>
          </div>

          {lgarabe ? (
            <div>
              <div
                style={{
                  height: "auto",
                  margin: "0 auto",
                  maxWidth: 80,
                  width: "100%",
                }}
              ></div>

              <div
                class="container-fluid"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div class="row g-0"></div>
                <div
                  style={{
                    alignItems: "left",
                    textAlign: "left",
                    alignContent: "left",
                  }}
                >
                  <div class="gallery-item">
                    <a
                      href=""
                      class="gallery-lightbox"
                      data-gall="gallery-item"
                    >
                      <img
                        src="assets/img/pfeS/Menu1arabe.png"
                        alt=""
                        class="img-fluid"
                      />
                    </a>
                    <a
                      href=""
                      class="gallery-lightbox"
                      data-gall="gallery-item"
                    >
                      <img
                        src="assets/img/pfeS/menu2arabe.png"
                        alt=""
                        class="img-fluid"
                      />
                    </a>
                    <a
                      href=""
                      class="gallery-lightbox"
                      data-gall="gallery-item"
                    >
                      <img
                        src="assets/img/pfeS/menu3arabe.png"
                        alt=""
                        class="img-fluid"
                      />
                    </a>
                    <a
                      href=""
                      class="gallery-lightbox"
                      data-gall="gallery-item"
                    >
                      <img
                        src="assets/img/pfeS/menu4arabe.png"
                        alt=""
                        class="img-fluid"
                      />
                    </a>
                    <a
                      href=""
                      class="gallery-lightbox"
                      data-gall="gallery-item"
                    >
                      <img
                        src="assets/img/pfeS/menu5arabe.png"
                        alt=""
                        class="img-fluid"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div>
              <div
                style={{
                  height: "auto",
                  margin: "0 auto",
                  maxWidth: 80,
                  width: "100%",
                }}
              ></div>

              <div
                class="container-fluid"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div class="row g-0"></div>
                <div
                  style={{
                    alignItems: "left",
                    textAlign: "left",
                    alignContent: "left",
                  }}
                >
                  <div class="gallery-item">
                    <a
                      href=""
                      class="gallery-lightbox"
                      data-gall="gallery-item"
                    >
                      <img
                        src="assets/img/pfeS/Menu1.png"
                        alt=""
                        class="img-fluid"
                      />
                    </a>
                    <a
                      href=""
                      class="gallery-lightbox"
                      data-gall="gallery-item"
                    >
                      <img
                        src="assets/img/pfeS/menu2.png"
                        alt=""
                        class="img-fluid"
                      />
                    </a>
                    <a
                      href=""
                      class="gallery-lightbox"
                      data-gall="gallery-item"
                    >
                      <img
                        src="assets/img/pfeS/menu3.png"
                        alt=""
                        class="img-fluid"
                      />
                    </a>
                    <a
                      href=""
                      class="gallery-lightbox"
                      data-gall="gallery-item"
                    >
                      <img
                        src="assets/img/pfeS/menu4.png"
                        alt=""
                        class="img-fluid"
                      />
                    </a>
                    <a
                      href=""
                      class="gallery-lightbox"
                      data-gall="gallery-item"
                    >
                      <img
                        src="assets/img/pfeS/menu5.png"
                        alt=""
                        class="img-fluid"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          )}

        
        </div>
      </section>*/}

      <Footer></Footer>
    </>
  );
}

export default MenuFR;
