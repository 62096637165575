import React, { useEffect, useState } from "react";
import $ from "jquery";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactWhatsapp from "react-whatsapp";
function FooterTR() {
  const navigate = useNavigate();
  const [msg, setmsg] = useState("");

  const data = { msg: msg };
  const sendmessage = (e) => {
    alert("message envoyé");
    // console.log(data);
  };
  return (
    <>
      <footer id="footer">
        <div class="footer-top">
          {/* <div class="container">
            <div class="text-center">
              <div class="row">
               
                 
                    <h1 class="logo me-auto me-lg-0">
                      {" "}
                      <p>
                        {" "}
                        <img
                          src="assets/img/pfeS/CaptureRim.png"
                          alt="saray restaurant turc"
                          class="img-fluid"
                          width={120}
                        />
                      </p>
                      
                 
                      <ul>
                    <li>
                 
                    <i class="bi bi-clock ">
                      <a> Mardi-Dimanche:</a>
                      <p> 08AM-23PM</p>
                    </i>
                 </li>
                 
                 <li>
                    <i class="bi bi-envelope">
                      <a>Contact@saray.tn</a>
                    </i>
                  </li>
                  </ul>
              
                    </h1>
                  
                </div> 
                 <div class="col-lg-3 col-md-6 footer-links">
                 
                  <ul>
                    <li>
                    <img
                          src="assets/img/pfeS/CaptureRim.png"
                          alt="saray restaurant turc"
                          class="img-fluid"
                          width={120}
                        />
                    </li>

                    <li>
                      <i class="bi bi-clock"></i>
                      <a href="https://www.gmail.com">
                      Lundi-Dimanche:08AM-23PM
                      </a>
                    </li>
                    <li>
                      <i class="bi bi-map"></i>
                      <a href="https://www.gmail.com">
                      Av. Hédi Chaker, Tunis, Tunisie
                      </a>
                    </li>
                   
                    <li>
                      <i class="bi bi-envelope"></i>
                      <a href="https://www.gmail.com">
                      Contact@saray.tn
                      </a>
                    </li>
                  </ul>
                </div>

                <div class="col-lg-3 col-md-6 footer-links">
                  <h4>Liens Utiles</h4>
                  <ul>
                    <li>
                      <i class="bx bx-chevron-right text-center"></i>{" "}
                      <a onClick={(e) => navigate("/")}>Accueil</a>
                    </li>

                    <li>
                      <i class="bx bx-chevron-right"></i>{" "}
                      <a onClick={(e) => navigate("/menu")}>Menu en turque</a>
                    </li>
                    <li>
                      <i class="bx bx-chevron-right"></i>{" "}
                      <a onClick={(e) => navigate("/menuarabe")}>
                        Menu en arabe
                      </a>
                    </li>
                    <li>
                      <i class="bx bx-chevron-right"></i>{" "}
                      <a onClick={(e) => navigate("/gallery")}>Gallery</a>
                    </li>
                  </ul>
                </div>

                <div class="col-lg-3 col-md-6 footer-links">
                  <h4>Réseaux sociaux</h4>
                  <ul>
                    <li>
                      <i class="bi bi-facebook"></i>
                      <a href="https://www.facebook.com/saraytunis">Facebook</a>
                    </li>

                    <li>
                      <i class="bi bi-instagram"></i>
                      <a href="https://www.instagram.com/saraytunisie/?igshid=MzMyNGUyNmU2YQ%3D%3D">
                        Instagram
                      </a>
                    </li>

                    <li>
                   
                    
                      <a href="https://wa.me/21626264616">+216 26 26 46 16</a>
                    </li>
                    <li>
                      <i class="fa-brands fa-tiktok"></i>

                      <a href="https://www.tiktok.com/@saray.turkish.cui">
                        TikTok
                      </a>
                    </li>
                  </ul>
                </div>

                <div class="col-lg-3 col-md-6 footer-links">
                  <h4>Av. Hédi Chaker, Tunis</h4>
                  <iframe
                 
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3194.0479882659492!2d10.179289774792245!3d36.81736996673321!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12fd35a32937f761%3A0x1aa018264134a771!2sSARAY%20Turkish%20Cuisine!5e0!3m2!1sfr!2stn!4v1699606294086!5m2!1sfr!2stn"
                    frameborder="0"
                    allowfullscreen
                  ></iframe>
                </div>
              </div>
            </div> */}
          </div>
       

        <div class="container">
          <div class="copyright">
            &copy;Telif Hakkı{" "}
            <strong>
              <span>Saray Turkich</span>
            </strong>
            Tüm Hakları Saklıdır
          </div>
        </div>
      </footer>
    </>
  );
}
export default FooterTR;
