import React, { useEffect, useState } from "react";
import $ from "jquery";
import FooterTR from "./FooterTR";
import HeaderTR from "./HeaderTR";
import Contact from "./Contact";
import Menu from "./Menu";
import About from "./About";
import ReactWhatsapp from "react-whatsapp";
import { useNavigate } from "react-router-dom";
function HomeTR() {
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [msg, setmsg] = useState("");
  const [subject, setsubject] = useState("");
  const [nbr, setnbr] = useState("");
  const [phone, setphone] = useState("");
  const [time, settime] = useState("");
  const [date, setdate] = useState("");
  const data = {
    name: name,
    email: email,
    phone: phone,
    date: date,
    time: time,
    msg: msg,
    nbr: nbr,
  };
  const sendmessage = () => {
    alert("réseravation envoyé");
  };
  const navigate = useNavigate();

  useEffect(() => {
    // jQuery code to execute on component mount
    $("#navbar").on("click", () => {});
  });

  return (
    <>
      <HeaderTR></HeaderTR>

      {/* <div id="topbar" class="d-flex align-items-center fixed-top">
        <div class="container d-flex justify-content-center justify-content-md-between">
          <div class="contact-info d-flex align-items-center">
            <i class=" d-flex align-items-center">
              <span></span>
            </i>
            <i class=" d-flex align-items-center ms-4">
              <span> </span>
            </i>
            <i class=" d-flex align-items-center ms-4">
              <span> </span>
            </i>
          </div>

          <div class="languages d-none d-md-flex align-items-center">
            <ul>
              <li>Fr</li>
              <li>
                <a href="#">Tu</a>
              </li>
            </ul>
          </div>
        </div>
      </div> */}

      <section id="hero" class="d-flex align-items-center">
        <div
          class="container position-relative text-center text-lg-start"
          data-aos="zoom-in"
          data-aos-delay="100"
        >
          <div class="row">
            <div class="col-lg-8">
              <h1>
                Restoran<span> Saray'a hoş geldiniz</span>
              </h1>
              {/* <h2>
                Déguster les plas turcs amoureusement préparés par nos chefs
              </h2> */}

              <div class="btns">
                <a
                  onClick={(e) => navigate("/menu")}
                  class="btn-menu animated fadeInUp scrollto"
                >
                  Menü
                </a>
                <a
                  onClick={(e) => navigate("/reservationTR")}
                  class="btn-book animated fadeInUp scrollto"
                >
                  Rezervasyon
                </a>
                <a
                  onClick={(e) => navigate("/organisationTR")}
                  class="btn-book animated fadeInUp scrollto"
                >
                  Organizasyon
                </a>
              </div>
            </div>
            {/* <div
              class="col-lg-4 d-flex align-items-center justify-content-center position-relative"
              data-aos="zoom-in"
              data-aos-delay="200"
            >
              <a
                href="https://www.facebook.com/reel/358441096651757"
                class="glightbox play-btn"
              ></a>
            </div> */}
            <p></p>
          </div>
          <p></p>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
        </div>
      </section>

      <main id="main">
        <section id="why-us" class="why-us">
          <div class="container" data-aos="fade-up">
            <div class="section-title">
              <h2>Türk</h2>
              <p>mutfağını keşfetmek</p>
            </div>

            <div class="row">
              <div class="col-lg-4">
                <div class="box" data-aos="zoom-in" data-aos-delay="100">
                  <span>01</span>
                  <h4>Türk Mutfağı</h4>
                  <p>
                    Ne ev sade, ne meşhur bir restoran, ne de bir beyin
                    malikanesi olsun, bu zengin ve çeşitli mutfakta her zaman
                    aynı tanıdık şablonları bulabiliriz. Bu, duyguları tatmin
                    eden ve toplumun, topluluğun ve kültürün üstünlüğünü yeniden
                    doğrulayan olağanüstü bir sanattır.
                  </p>
                </div>
              </div>

              <div class="col-lg-4 mt-4 mt-lg-0">
                <div class="box" data-aos="zoom-in" data-aos-delay="200">
                  <span>02</span>
                  <h4>Çeşitlilik</h4>
                  <p>
                    Mutfak işlerine meraklı olanlar için, Türk mutfağı çok
                    alışılmadık bir yerdir. Oluşturan yemeklerin çeşitliliği,
                    gerçek ziyafetlere dönüşen şekilleri ve her kategorinin
                    açıkça karmaşıklığı, bu kadar zenginliği sunar ki, insan
                    ömrünü onu çalışarak ve tadarak geçirebilir.
                  </p>
                </div>
              </div>

              <div class="col-lg-4 mt-4 mt-lg-0">
                <div class="box" data-aos="zoom-in" data-aos-delay="300">
                  <span>03</span>
                  <h4> Miras</h4>
                  <p>
                    Türk mutfağı Osmanlı mirasından faydalanmakta olup, Asya,
                    Doğu ve Akdeniz tatlarının kesişimindedir. Gerçekten de,
                    Türk mutfağı, Türklerin yüzyıllar boyunca Orta Asya'dan
                    Avrupa'ya göçleri ile zenginleşmiş ve komşu ülkelerini
                    etkilemiştir.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <section id="specials" class="specials">
          <div class="container" data-aos="fade-up">
            <div class="section-title">
              <h2>Specials</h2>
              <p>Découvrir nos plats</p>
            </div>

            <div class="row" data-aos="fade-up" data-aos-delay="100">
              <div class="col-lg-3">
                <ul class="nav nav-tabs flex-column">
                  <li class="nav-item">
                    <a
                      class="nav-link active show"
                      data-bs-toggle="tab"
                      href="#tab-1"
                    >
                      KABAB
                    </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" data-bs-toggle="tab" href="#tab-2">
                      CHICH
                    </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" data-bs-toggle="tab" href="#tab-3">
                      TAOUK
                    </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" data-bs-toggle="tab" href="#tab-4">
                      CHAWARMA
                    </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" data-bs-toggle="tab" href="#tab-5">
                      SANDWITCH
                    </a>
                  </li>
                </ul>
              </div>
              <div class="col-lg-9 mt-4 mt-lg-0">
                <div class="tab-content">
                  <div class="tab-pane active show" id="tab-1">
                    <div class="row">
                      <div class="col-lg-8 details order-2 order-lg-1">
                        <h3>Plat KABAB</h3>
                        <p class="fst-italic">
                          Qui laudantium consequatur laborum sit qui ad sapiente
                          dila parde sonata raqer a videna mareta paulona marka
                        </p>
                        <p>
                          Et nobis maiores eius. Voluptatibus ut enim blanditiis
                          atque harum sint. Laborum eos ipsum ipsa odit magni.
                          Incidunt hic ut molestiae aut qui. Est repellat minima
                          eveniet eius et quis magni nihil. Consequatur dolorem
                          quaerat quos qui similique accusamus nostrum rem vero
                        </p>
                      </div>
                      <div class="col-lg-4 text-center order-1 order-lg-2">
                        <img
                          src="assets/img/pfeS/s10.png"
                          alt=""
                          class="img-fluid"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane" id="tab-2">
                    <div class="row">
                      <div class="col-lg-8 details order-2 order-lg-1">
                        <h3>PLAT CHICH</h3>
                        <p class="fst-italic">
                          Qui laudantium consequatur laborum sit qui ad sapiente
                          dila parde sonata raqer a videna mareta paulona marka
                        </p>
                        <p>
                          Ea ipsum voluptatem consequatur quis est. Illum error
                          ullam omnis quia et reiciendis sunt sunt est. Non
                          aliquid repellendus itaque accusamus eius et velit
                          ipsa voluptates. Optio nesciunt eaque beatae accusamus
                          lerode pakto madirna desera vafle de nideran pal
                        </p>
                      </div>
                      <div class="col-lg-4 text-center order-1 order-lg-2">
                        <img
                          src="assets/img/pfeS/s11.png"
                          alt=""
                          class="img-fluid"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane" id="tab-3">
                    <div class="row">
                      <div class="col-lg-8 details order-2 order-lg-1">
                        <h3>PLAT TAOUK</h3>
                        <p class="fst-italic">
                          Eos voluptatibus quo. Odio similique illum id quidem
                          non enim fuga. Qui natus non sunt dicta dolor et. In
                          asperiores velit quaerat perferendis aut
                        </p>
                        <p>
                          Iure officiis odit rerum. Harum sequi eum illum
                          corrupti culpa veritatis quisquam. Neque
                          necessitatibus illo rerum eum ut. Commodi ipsam minima
                          molestiae sed laboriosam a iste odio. Earum odit
                          nesciunt fugiat sit ullam. Soluta et harum voluptatem
                          optio quae
                        </p>
                      </div>
                      <div class="col-lg-4 text-center order-1 order-lg-2">
                        <img
                          src="assets/img/pfeS/s13.png"
                          alt=""
                          class="img-fluid"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane" id="tab-4">
                    <div class="row">
                      <div class="col-lg-8 details order-2 order-lg-1">
                        <h3>PLAT CHAWARMA</h3>
                        <p class="fst-italic">
                          Totam aperiam accusamus. Repellat consequuntur iure
                          voluptas iure porro quis delectus
                        </p>
                        <p>
                          Eaque consequuntur consequuntur libero expedita in
                          voluptas. Nostrum ipsam necessitatibus aliquam fugiat
                          debitis quis velit. Eum ex maxime error in consequatur
                          corporis atque. Eligendi asperiores sed qui veritatis
                          aperiam quia a laborum inventore
                        </p>
                      </div>
                      <div class="col-lg-4 text-center order-1 order-lg-2">
                        <img
                          src="assets/img/pfeS/s14.png"
                          alt=""
                          class="img-fluid"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane" id="tab-5">
                    <div class="row">
                      <div class="col-lg-8 details order-2 order-lg-1">
                        <h3>PLAT SANDWITCH</h3>
                        <p class="fst-italic">
                          Omnis blanditiis saepe eos autem qui sunt debitis
                          porro quia.
                        </p>
                        <p>
                          Exercitationem nostrum omnis. Ut reiciendis
                          repudiandae minus. Omnis recusandae ut non quam ut
                          quod eius qui. Ipsum quia odit vero atque qui
                          quibusdam amet. Occaecati sed est sint aut vitae
                          molestiae voluptate vel
                        </p>
                      </div>
                      <div class="col-lg-4 text-center order-1 order-lg-2">
                        <img
                          src="assets/img/pfeS/s15.png"
                          alt=""
                          class="img-fluid"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        {/* <Events></Events> */}

        {/* <section id="testimonials" class="testimonials section-bg">
          <div class="container" data-aos="fade-up">
            <div class="section-title">
              <h2>Testimonials</h2>
              <p>What they're saying about us</p>
            </div>

            <div
              class="testimonials-slider swiper-container"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <div class="swiper-wrapper">
                <div class="swiper-slide">
                  <div class="testimonial-item">
                    <p>
                      <i class="bx bxs-quote-alt-left quote-icon-left"></i>
                      Proin iaculis purus consequat sem cure digni ssim donec
                      porttitora entum suscipit rhoncus. Accusantium quam,
                      ultricies eget id, aliquam eget nibh et. Maecen aliquam,
                      risus at semper.
                      <i class="bx bxs-quote-alt-right quote-icon-right"></i>
                    </p>
                    <img
                      src="assets/pfeS/testimonials/testimonials-1.jpg"
                      class="testimonial-img"
                      alt=""
                    />
                    <h3>Saul Goodman</h3>
                    <h4>Ceo &amp; Founder</h4>
                  </div>
                </div>

                <div class="swiper-slide">
                  <div class="testimonial-item">
                    <p>
                      <i class="bx bxs-quote-alt-left quote-icon-left"></i>
                      Export tempor illum tamen malis malis eram quae irure esse
                      labore quem cillum quid cillum eram malis quorum velit
                      fore eram velit sunt aliqua noster fugiat irure amet legam
                      anim culpa.
                      <i class="bx bxs-quote-alt-right quote-icon-right"></i>
                    </p>
                    <img
                      src="assets/pfeS/testimonials/testimonials-2.jpg"
                      class="testimonial-img"
                      alt=""
                    />
                    <h3>Sara Wilsson</h3>
                    <h4>Designer</h4>
                  </div>
                </div>

                <div class="swiper-slide">
                  <div class="testimonial-item">
                    <p>
                      <i class="bx bxs-quote-alt-left quote-icon-left"></i>
                      Enim nisi quem export duis labore cillum quae magna enim
                      sint quorum nulla quem veniam duis minim tempor labore
                      quem eram duis noster aute amet eram fore quis sint minim.
                      <i class="bx bxs-quote-alt-right quote-icon-right"></i>
                    </p>
                    <img
                      src="assets/pfeS/testimonials/testimonials-3.jpg"
                      class="testimonial-img"
                      alt=""
                    />
                    <h3>Jena Karlis</h3>
                    <h4>Store Owner</h4>
                  </div>
                </div>

                <div class="swiper-slide">
                  <div class="testimonial-item">
                    <p>
                      <i class="bx bxs-quote-alt-left quote-icon-left"></i>
                      Fugiat enim eram quae cillum dolore dolor amet nulla culpa
                      multos export minim fugiat minim velit minim dolor enim
                      duis veniam ipsum anim magna sunt elit fore quem dolore
                      labore illum veniam.
                      <i class="bx bxs-quote-alt-right quote-icon-right"></i>
                    </p>
                    <img
                      src="assets/pfeS/testimonials/testimonials-4.jpg"
                      class="testimonial-img"
                      alt=""
                    />
                    <h3>Matt Brandon</h3>
                    <h4>Freelancer</h4>
                  </div>
                </div>

                <div class="swiper-slide">
                  <div class="testimonial-item">
                    <p>
                      <i class="bx bxs-quote-alt-left quote-icon-left"></i>
                      Quis quorum aliqua sint quem legam fore sunt eram irure
                      aliqua veniam tempor noster veniam enim culpa labore duis
                      sunt culpa nulla illum cillum fugiat legam esse veniam
                      culpa fore nisi cillum quid.
                      <i class="bx bxs-quote-alt-right quote-icon-right"></i>
                    </p>
                    <img
                      src="assets/pfeS/testimonials/testimonials-5.jpg"
                      class="testimonial-img"
                      alt=""
                    />
                    <h3>John Larson</h3>
                    <h4>Entrepreneur</h4>
                  </div>
                </div>
              </div>
              <div class="swiper-pagination"></div>
            </div>
          </div>
        </section> */}

        <section id="chefs" class="chefs">
          <div class="container" data-aos="fade-up">
            <div class="section-title">
              <h2>Bizim</h2>
              <p>Türk ekibimiz</p>
            </div>

            <div class="row">
              <div class="col-lg-4 col-md-6">
                <div class="member" data-aos="zoom-in" data-aos-delay="100">
                  <img
                    src="assets/img/pfeS/s50.jpg"
                    class="img-fluid"
                    alt="cuisine turque "
                    title="turkish food"
                  />
                  <div class="member-info">
                    <div class="member-info-content"></div>
                    {/* <div class="social">
                      <a href="">
                        <i class="bi bi-twitter"></i>
                      </a>
                      <a href="">
                        <i class="bi bi-facebook"></i>
                      </a>
                      <a href="">
                        <i class="bi bi-instagram"></i>
                      </a>
                      <a href="">
                        <i class="bi bi-linkedin"></i>
                      </a>
                    </div> */}
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-md-6">
                <div class="member" data-aos="zoom-in" data-aos-delay="200">
                  <img
                    src="assets/img/pfeS/s51.jpg"
                    class="img-fluid"
                    alt="cuisine turque "
                    title="turkish food"
                  />
                  <div class="member-info">
                    <div class="member-info-content"></div>
                    {/* <div class="social">
                      <a href="">
                        <i class="bi bi-twitter"></i>
                      </a>
                      <a href="">
                        <i class="bi bi-facebook"></i>
                      </a>
                      <a href="">
                        <i class="bi bi-instagram"></i>
                      </a>
                      <a href="">
                        <i class="bi bi-linkedin"></i>
                      </a>
                    </div> */}
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-md-6">
                <div class="member" data-aos="zoom-in" data-aos-delay="300">
                  <img
                    alt="cuisine turque "
                    title="turkish food"
                    src="assets/img/pfeS/s54.jpg"
                    class="img-fluid"
                    width={240}
                  />
                  <div class="member-info">
                    <div class="member-info-content"></div>
                    {/* <div class="social">
                      <a href="">
                        <i class="bi bi-twitter"></i>
                      </a>
                      <a href="">
                        <i class="bi bi-facebook"></i>
                      </a>
                      <a href="">
                        <i class="bi bi-instagram"></i>
                      </a>
                      <a href="">
                        <i class="bi bi-linkedin"></i>
                      </a>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      <FooterTR></FooterTR>

      {/* <div id="preloader"></div> */}
      <a
        href="#"
        class="back-to-top d-flex align-items-center justify-content-center"
      >
        <i class="bi bi-arrow-up-short"></i>
      </a>
    </>
  );
}

export default HomeTR;
