import React, { useEffect, useState } from "react";
import Footer from "./Footer";
import Header from "./Header";

import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
//import data from "./data.json";
import data from "./dataarabe";

import "react-medium-image-zoom/dist/styles.css";
import HeaderAR from "./HeaderAR";
import FooterAR from "./FooterAR";
function MenuAR() {
  const [lgarabe, setlgarabe] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState(data);
  const [selectedValue, setSelectedValue] = useState("");

  useEffect(() => {
    setFilteredData(data);
  }, []);
  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
    if (event.target.value == "Entrées") {
      alert("ok entre");
      handleInputChangeEntré();
    }
    if (event.target.value == "Sandwichs") {
      handleInputChangeSandwitch();
    }
    if (event.target.value == "Salades") {
      handleInputChangeSalade();
    }
    if (event.target.value == "PİDE & LAHMACUN") {
      handleInputChangepide();
    }
    if (event.target.value == "PLATS") {
      handleInputChangeWajba();
    }
    if (event.target.value == "Plats grillés") {
      handleInputChangegratin();
    }
    if (event.target.value == "Plats spéciaux") {
      handleInputChangePlatspecials();
    }
    if (event.target.value == "Supplément") {
      handleInputChangesupplement();
    }
    if (event.target.value == "Desserts") {
      handleInputChangeSucré();
    }

    if (event.target.value == "Boissons froides") {
      handleInputChangefroid();
    }
    if (event.target.value == "Boissons chaudes") {
      handleInputChangechaud();
    }
  };
  const handleInputChange = (event) => {
    const { value } = event.target;
    // setSearchTerm(value);
    setSearchTerm(event.target.value.toLowerCase());
    filterData(value);
  };

  const filterData = (searchTerm) => {
    const filteredData = data.filter(
      (item) =>
        //  item.data.name &&  item.data.name.toLowerCase().includes(searchTerm.toLowerCase())
        item.data.name && item.data.name.toLowerCase().includes(searchTerm)
    );
    setFilteredData(filteredData);
  };

  const handleInputChangeEntré = (event) => {
    const { value } = "Entrées";
    setSearchTerm(value);
    filterDataentré(value);
  };
  const filterDataentré = (searchTerm) => {
    const filtered = data.filter((item) => item.category == "Entrées");
    setFilteredData(filtered);
  };
  const handleInputChangeSalade = (event) => {
    const { value } = "Salades";
    setSearchTerm(value);
    filterDatasalade(value);
  };
  const filterDatasalade = (searchTerm) => {
    const filtered = data.filter((item) => item.category == "Salades");
    setFilteredData(filtered);
  };
  const handleInputChangeSandwitch = (event) => {
    const { value } = "Sandwichs";
    //setSearchTerm(value);
    filterDatasandwitch(value);
  };
  const filterDatasandwitch = (searchTerm) => {
    const filtered = data.filter((item) => item.category == "Sandwichs");
    setFilteredData(filtered);
  };
  const handleInputChangeSucré = (event) => {
    const { value } = "Desserts";
    setSearchTerm(value);
    filterDatasucré(value);
  };
  const filterDatasucré = (searchTerm) => {
    const filtered = data.filter((item) => item.category == "Desserts");
    setFilteredData(filtered);
  };

  const handleInputChangeWajba = (event) => {
    const { value } = "PLATS";
    setSearchTerm(value);
    filterDatawajba(value);
  };
  const filterDatawajba = (searchTerm) => {
    const filtered = data.filter((item) => item.category == "PLATS");
    setFilteredData(filtered);
  };

  const handleInputChangepide = (event) => {
    const { value } = "PİDE & LAHMACUN";
    setSearchTerm(value);
    filterDatapide(value);
  };
  const filterDatapide = (searchTerm) => {
    const filtered = data.filter((item) => item.category == "PİDE & LAHMACUN");
    setFilteredData(filtered);
  };

  const handleInputChangegratin = (event) => {
    const { value } = "Plats grillés";
    setSearchTerm(value);
    filterDatagratin(value);
  };
  const filterDatagratin = (searchTerm) => {
    const filtered = data.filter((item) => item.category == "Plats grillés");
    setFilteredData(filtered);
  };

  const handleInputChangesupplement = (event) => {
    const { value } = "Supplément";
    setSearchTerm(value);
    filterDatasupplement(value);
  };
  const filterDatasupplement = (searchTerm) => {
    const filtered = data.filter((item) => item.category == "Supplément");
    setFilteredData(filtered);
  };

  const handleInputChangepain = (event) => {
    const { value } = "Pains";
    setSearchTerm(value);
    filterDatapain(value);
  };
  const filterDatapain = (searchTerm) => {
    const filtered = data.filter((item) => item.category == "Pains");
    setFilteredData(filtered);
  };

  const handleInputChangechaud = (event) => {
    const { value } = "Boissons chaudes";
    setSearchTerm(value);
    filterDatachaud(value);
  };
  const filterDatachaud = (searchTerm) => {
    const filtered = data.filter((item) => item.category == "Boissons chaudes");
    setFilteredData(filtered);
  };

  const handleInputChangefroid = (event) => {
    const { value } = "Boissons froides";
    setSearchTerm(value);
    filterDatafroid(value);
  };
  const filterDatafroid = (searchTerm) => {
    const filtered = data.filter((item) => item.category == "Boissons froides");
    setFilteredData(filtered);
  };

  const handleInputChangePlatspecials = (event) => {
    const { value } = "Plats spéciaux";
    setSearchTerm(value);
    filterDataspecial(value);
  };
  const filterDataspecial = (searchTerm) => {
    const filtered = data.filter((item) => item.category == "Plats spéciaux");
    setFilteredData(filtered);
  };

  const handleInputChangeTous = (event) => {
    setFilteredData(data);
  };
  const changelangue = (e) => {
    if (lgarabe) {
      setlgarabe(false);
    } else if (lgarabe == false) {
      setlgarabe(true);
    }
  };
  return (
    <>
      <HeaderAR></HeaderAR>

      <section id="menu" class="menu section-bg">
        <div class="container" data-aos="fade-up">
          {/* <section id="gallery" class="gallery">
            <div class="container" data-aos="fade-up">
              <div class="section-title">
                <p>Notre Menu</p>
              </div>
            </div>
          </section> */}
          <div class="row" data-aos="fade-up" data-aos-delay="100">
            <div>
              <p style={{ color: "#cda45e", float: "right" }}>قائمة الطعام</p>
                    
            </div>
            <select
                value={selectedValue}
                onChange={handleSelectChange}
                class="col-lg-3"
               
              >
                <option value="">اختار </option>
                <option value="Entrées">المقبلات </option>
                <option value="Salades">سلطات</option>
                <option value="Sandwichs">ساندويشات </option>
                <option value="PİDE & LAHMACUN">بيدا & لحماجون</option>
                <option value="PLATS">وﺟﺒﺎت</option>
                <option value="Plats grillés">وجبات مشوية مشكلة</option>
                <option value="Plats spéciaux">وجبات خاصة</option>

                <option value="Supplément">المكاملات</option>
                <option value="Desserts"> حلويات</option>
                <option value="Boissons froides">ﻣﺸﺮوﺑﺎت ﺑﺎردة</option>
                <option value="Boissons chaudes">ﻣﺸﺮوﺑﺎت ﺳﺎﺧﻨﺔ</option>
              </select>
            <div class="col-lg-12 d-flex ">
              {/* <div class="col-lg-6">
                <input
                  type="text"
                  placeholder="Search..."
                  value={searchTerm}
                  onChange={handleInputChange}
                />
              </div>*/}
            </div>
          </div>
        </div>

        {filteredData.map((item) => (
          <div
            key={item.id}
            class="row menu-container"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            <div class="row justify-content-center menu-item filter-starters">
              <Zoom>
                <div class="row justify-content-center">
                  <a class="row justify-content-center" href="#"></a>
                  <img
                    src={item.img}
                    class="menu-img img-fluid"
                    alt="cuisine turque "
                    title="turkish food"
                    width={50}
                  />
                </div>
              </Zoom>
              <p></p>
              <div class="col-lg-12 justify-content-center  menu-content">
                <p>
                  <h1 style={{ color: "#cda45e", float: "right" }}>
                    {" "}
                    {item.categoryAR}
                  </h1>
                  <br></br>
                  <br></br>
                  <br></br>
                  <ul style={{ color: "#cda45e", float: "right" }}>
                    {Array.isArray(item.data) &&
                      item.data.map((item2, innerIndex) => (
                        <a href="#">
                          <li key={innerIndex}>
                            <a href="#">
                              {" "}
                              {item2.nameAR} <span> {item2.price} دينار</span>
                            </a>
                          </li>
                        </a>
                      ))}
                  </ul>
                </p>

                {/* <span>{item.price}</span> */}
              </div>
            </div>
          </div>
        ))}
      </section>

      <FooterAR></FooterAR>
    </>
  );
}

export default MenuAR;
