import React, { useEffect, useState } from "react";
import ReactWhatsapp from "react-whatsapp";
import Footer from "./Footer";
import { useNavigate } from "react-router-dom";
import Header from "./Header";
function Organisation() {
  const navigate = useNavigate();
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [msg, setmsg] = useState("");

  const [nbr, setnbr] = useState("");
  const [phone, setphone] = useState("");
  const [time, settime] = useState("");
  const [date, setdate] = useState("");

  const [selectedValue, setSelectedValue] = useState("");
  const data = {
    name: name,
    email: email,
    phone: phone,
    date: date,
    time: time,
    msg: msg,
    nbr: nbr,
    selectedValue: selectedValue,
  };

  // Function to handle change in select
  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
  };

  // Function to display the selected value in an alert

  const sendmessage = () => {
    alert("réseravation envoyée");
  };

  return (
    <>
      <Header></Header>

      <section id="book-a-table" class="book-a-table">
        <div class="container" data-aos="fade-up">
          <div class="section-title">
            <h2>Organiser votre événement</h2>
            <p>par WhatsApp</p>
          </div>

          <form
            // method="post"
            role="form"
            class="php-email-form"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <div class="row">
              <div class="col-lg-4 col-md-6 form-group">
                <input
                  type="text"
                  name="name"
                  class="form-control"
                  id="name"
                  value={name}
                  onChange={(e) => setname(e.target.value)}
                  placeholder="Nom"
                  data-rule="minlen:4"
                  data-msg="Please enter at least 4 chars"
                />
                <div class="validate"></div>
              </div>
              <div class="col-lg-4 col-md-6 form-group mt-3 mt-md-0">
                <input
                  type="email"
                  class="form-control"
                  name="email"
                  id="email"
                  value={email}
                  onChange={(e) => setemail(e.target.value)}
                  placeholder="Email"
                  data-rule="email"
                  data-msg="Please enter a valid email"
                />
                <div class="validate"></div>
              </div>
              <div class="col-lg-4 col-md-6 form-group mt-3 mt-md-0">
                <input
                  type="text"
                  class="form-control"
                  name="phone"
                  id="phone"
                  value={phone}
                  onChange={(e) => setphone(e.target.value)}
                  placeholder="téléphone"
                  data-rule="minlen:4"
                  data-msg="Please enter at least 4 chars"
                />
                <div class="validate"></div>
              </div>
              <div class="col-lg-4 col-md-6 form-group mt-3">
                <select
                  value={selectedValue}
                  onChange={handleSelectChange}
                  class="form-control"
                >
                  <option value="">Type d'événement </option>
                  <option value="anniversiare">Anniversaire</option>
                  <option value="mariage">Mariage</option>
                  <option value="circoncision">Circoncision</option>
                  <option value="projet de fin d'étude">
                    Projet de fin d'étude
                  </option>
                  <option value="team building">Team building</option>
                  <option value="conférence">Conférence</option>
                  <option value="ouverture">Ouverture</option>
                </select>

                {/* <button onClick={showAlert}>Show Selected Value</button> */}
                <div class="validate"></div>
              </div>
              <div class="col-lg-4 col-md-6 form-group mt-3">
                <input
                  type="date"
                  name="date"
                  class="form-control"
                  id="date"
                  value={date}
                  onChange={(e) => setdate(e.target.value)}
                  placeholder="Date"
                  data-rule="minlen:4"
                  data-msg="Please enter at least 4 chars"
                />
                <div class="validate"></div>
              </div>

              <div class="col-lg-4 col-md-6 form-group mt-3">
                <input
                  type="time"
                  class="form-control"
                  name="time"
                  id="time"
                  value={time}
                  onChange={(e) => settime(e.target.value)}
                  placeholder="Horaire"
                  data-rule="minlen:4"
                  data-msg="Please enter at least 4 chars"
                />
                <div class="validate"></div>
              </div>
              <div class="col-lg-4 col-md-6 form-group mt-3">
                <input
                  type="number"
                  class="form-control"
                  name="people"
                  id="people"
                  value={nbr}
                  onChange={(e) => setnbr(e.target.value)}
                  placeholder="Nb personnes"
                  data-rule="minlen:1"
                  data-msg="Please enter at least 1 chars"
                />
                <div class="validate"></div>
              </div>

              <div class="col-lg-8 col-md-6 form-group mt-3">
                <input
                  class="form-control"
                  name="message"
                  rows="2"
                  value={msg}
                  onChange={(e) => setmsg(e.target.value)}
                  placeholder="Message"
                ></input>
                <div class="validate"></div>
              </div>
            </div>

            <div class="text-center">
              <div class="col-lg-12">
                {/* <div class="col-lg-4 col-xs-3 offset-3"> */}
                <ReactWhatsapp
                  class="bi bi-whatsapp"
                  type="submit"
                  style={{
                    backgroundColor: "#cda45e",

                    width: 80,
                    height: 45,
                    borderRadius: 25,
                  }}
                  number="216-26-26-4616"
                  onClick={(e) => sendmessage(e)}
                  message={
                    "Demande d'organisation pour: " +
                    data.selectedValue +
                    "pour le client:  " +
                    data.name +
                    "  email:  " +
                    data.email +
                    "  phone:  " +
                    data.phone +
                    "  date:  " +
                    data.date +
                    "  time:  " +
                    data.time +
                    "  nombre de personne:  " +
                    data.nbr +
                    "  message:  " +
                    data.msg
                  }
                />
                {/* </div> */}
              </div>
              <br></br>
              {/* <div class="col-lg-12">
               <div class="col-lg-4 col-xs-3 offset-3">
                   <button
                type="submit"
                style={{
                  backgroundColor: "#cda45e",
                   alignItems:"center",
                   textAlign:"center",
                   alignContent:"center",
                  width: 180,
                  height: 45,
                  borderRadius: 25,
                }}
                  onClick={(e) => navigate(-1)}>retour
                </button> 
                </div>
              </div> */}
            </div>
          </form>
        </div>
      </section>
      <Footer></Footer>
    </>
  );
}

export default Organisation;
