import React, { useEffect, useState } from "react";
import ReactWhatsapp from "react-whatsapp";
import FooterTR from "./FooterTR";
import { useNavigate } from "react-router-dom";

import HeaderTR from "./HeaderTR";
function OrganisationTR() {
  const navigate = useNavigate();
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [msg, setmsg] = useState("");
  const [subject, setsubject] = useState("");
  const [nbr, setnbr] = useState("");
  const [phone, setphone] = useState("");
  const [time, settime] = useState("");
  const [date, setdate] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const data = {
    name: name,
    email: email,
    phone: phone,
    date: date,
    time: time,
    msg: msg,
    nbr: nbr,
    selectedValue: selectedValue,
  };

  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const sendmessage = () => {
    alert("réseravation envoyée");
  };
  return (
    <>
      <HeaderTR></HeaderTR>

      <section id="book-a-table" class="book-a-table">
        <div class="container" data-aos="fade-up">
          <div class="section-title">
            <h2>WhatsApp</h2>
            <p>etkinliğinizi düzenlemek</p>
          </div>

          <form
            // method="post"
            role="form"
            class="php-email-form"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <div class="row">
              <div class="col-lg-4 col-md-6 form-group">
                <input
                  type="text"
                  name="name"
                  class="form-control"
                  id="name"
                  value={name}
                  onChange={(e) => setname(e.target.value)}
                  placeholder="İsim"
                  data-rule="minlen:4"
                  data-msg="Please enter at least 4 chars"
                />
                <div class="validate"></div>
              </div>
              <div class="col-lg-4 col-md-6 form-group mt-3 mt-md-0">
                <input
                  type="email"
                  class="form-control"
                  name="email"
                  id="email"
                  value={email}
                  onChange={(e) => setemail(e.target.value)}
                  placeholder="E-posta"
                  data-rule="email"
                  data-msg="Please enter a valid email"
                />
                <div class="validate"></div>
              </div>
              <div class="col-lg-4 col-md-6 form-group mt-3 mt-md-0">
                <input
                  type="text"
                  class="form-control"
                  name="phone"
                  id="phone"
                  value={phone}
                  onChange={(e) => setphone(e.target.value)}
                  placeholder="Telefon"
                  data-rule="minlen:4"
                  data-msg="Please enter at least 4 chars"
                />
                <div class="validate"></div>
              </div>
              <div class="col-lg-4 col-md-6 form-group mt-3">
                <select
                  value={selectedValue}
                  onChange={handleSelectChange}
                  class="form-control"
                >
                  <option value="">Etkinlik türü</option>
                  <option value="Doğum günü">Doğum günü</option>
                  <option value="Düğün"> Düğün</option>
                  <option value="Sünnet">Sünnet</option>
                  <option value="Mezuniyet projesi">Mezuniyet projesi</option>
                  <option value="Takım oluşturma">Takım oluşturma</option>
                  <option value="Konferans">Konferans</option>
                  <option value="Açılış">Açılış</option>
                </select>

                {/* <button onClick={showAlert}>Show Selected Value</button> */}
                <div class="validate"></div>
              </div>
              <div class="col-lg-4 col-md-6 form-group mt-3">
                <input
                  type="date"
                  name="date"
                  class="form-control"
                  id="date"
                  value={date}
                  onChange={(e) => setdate(e.target.value)}
                  placeholder="Date"
                  data-rule="minlen:4"
                  data-msg="Please enter at least 4 chars"
                />
                <div class="validate"></div>
              </div>
              <div class="col-lg-4 col-md-6 form-group mt-3">
                <input
                  type="time"
                  class="form-control"
                  name="time"
                  id="time"
                  value={time}
                  onChange={(e) => settime(e.target.value)}
                  placeholder="Time"
                  data-rule="minlen:4"
                  data-msg="Please enter at least 4 chars"
                />
                <div class="validate"></div>
              </div>
              <div class="col-lg-4 col-md-6 form-group mt-3">
                <input
                  type="number"
                  class="form-control"
                  name="people"
                  id="people"
                  value={nbr}
                  onChange={(e) => setnbr(e.target.value)}
                  placeholder="Kişi Sayısı"
                  data-rule="minlen:1"
                  data-msg="Please enter at least 1 chars"
                />
                <div class="validate"></div>
              </div>
            </div>
            <div class="form-group mt-3">
              <textarea
                class="form-control"
                name="message"
                rows="5"
                value={msg}
                onChange={(e) => setmsg(e.target.value)}
                placeholder="Message"
              ></textarea>
              <div class="validate"></div>
            </div>

            <div class="text-center">
              <div class="col-lg-12">
                {/* <div class="col-lg-4 col-xs-3 offset-3"> */}
                <ReactWhatsapp
                  class="bi bi-whatsapp"
                  type="submit"
                  style={{
                    backgroundColor: "#cda45e",

                    width: 80,
                    height: 45,
                    borderRadius: 25,
                  }}
                  number="216-26-26-4616"
                  onClick={(e) => sendmessage(e)}
                  message={
                    " réservation pour  client:  " +
                    data.name +
                    "  email:  " +
                    data.email +
                    "  phone:  " +
                    data.phone +
                    "  date:  " +
                    data.date +
                    "  time:  " +
                    data.time +
                    "  nombre de personne:  " +
                    data.nbr +
                    "  message:  " +
                    data.msg
                  }
                />
                {/* </div> */}
              </div>
              <br></br>
              {/* <div class="col-lg-12">
                <div class="col-lg-4 col-xs-3 offset-3">
                  <button
                    type="submit"
                    style={{
                      backgroundColor: "#cda45e",
                      alignItems: "center",
                      textAlign: "center",
                      alignContent: "center",
                      width: 180,
                      height: 45,
                      borderRadius: 25,
                    }}
                    onClick={(e) => navigate(-1)}
                  >
                    retour
                  </button>
                </div>
              </div> */}
            </div>
          </form>
        </div>
      </section>
      <FooterTR></FooterTR>
    </>
  );
}

export default OrganisationTR;
