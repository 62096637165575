import React, { useEffect, useState } from "react";
import ReactWhatsapp from "react-whatsapp";
import Footer from "./Footer";
import Header from "./Header";
import { useNavigate } from "react-router-dom";
function Contact() {
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [msg, setmsg] = useState("");
  const [subject, setsubject] = useState("");
  const data = { name: name, email: email, subject: subject, msg: msg };
  const navigate = useNavigate();

  const sendmessage = (e) => {
    alert("message envoyé");
    // console.log(data);
  };
  return (
    <>
      <Header></Header>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <section id="contact" class="contact">
        <div class="container" data-aos="fade-up">
          <div class="section-title">
            <h2>Contact</h2>
            <p>Nos contacts</p>
          </div>
        </div>

        <div class="container " data-aos="fade-up">
          <div class="row mt-5">
            {/* </div> <div class="col-lg-10 mt-5 mt-lg-0 google-map"> */}
            <div class="col-lg-12">
              <iframe
                //  style={{ border: 0, width: 500, height: 350 }}
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3194.0479882659492!2d10.179289774792245!3d36.81736996673321!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12fd35a32937f761%3A0x1aa018264134a771!2sSARAY%20Turkish%20Cuisine!5e0!3m2!1sfr!2stn!4v1699606294086!5m2!1sfr!2stn"
                frameborder="0"
                allowfullscreen
                // class="google-map"
              ></iframe>
            </div>
          </div>
          <div class="row mt-5">
            <div class="col-lg-4">
              <div class="info">
                <div class="address">
                  <i class="bi bi-geo-alt"></i>
                  <h4>Adresse:</h4>
                  <p>Av. Hédi Chaker, Tunis, Gouvernorat de Tunis, Tunisie</p>
                </div>

                <div class="open-hours">
                  <i class="bi bi-clock"></i>
                  <h4>Disponibilité:</h4>
                  <p>Mar-Ven: 08:00 AM - 23:00 PM</p>
                  <p>Samedi: 09:00 AM - 24:00 PM</p>
                </div>

                <div class="email">
                  <i class="bi bi-envelope"></i>
                  <h4>Email:</h4>
                  <p>Contact@saray.tn</p>
                </div>

                <div class="phone">
                  <i class="bi bi-phone"></i>
                  <h4>Appel:</h4>
                  <p>+216 26 26 46 16</p>
                </div>
              </div>
            </div>

            <div class="text-center">
              <div class="col-lg-8 mt-5 mt-lg-0">
                <form role="form" class="php-email-form">
                  <div class="row">
                    <div class="col-md-6 form-group">
                      <input
                        type="text"
                        name="name"
                        class="form-control"
                        id="name"
                        value={name}
                        onChange={(e) => setname(e.target.value)}
                        placeholder="Your Name"
                        required
                      />
                    </div>
                    <div class="col-md-6 form-group mt-3 mt-md-0">
                      <input
                        type="email"
                        class="form-control"
                        name="email"
                        id="email"
                        value={email}
                        onChange={(e) => setemail(e.target.value)}
                        placeholder="Your Email"
                        required
                      />
                    </div>
                  </div>
                  <div class="form-group mt-3">
                    <input
                      type="text"
                      class="form-control"
                      name="subject"
                      id="subject"
                      value={subject}
                      onChange={(e) => setsubject(e.target.value)}
                      placeholder="Subject"
                      required
                    />
                  </div>
                  <div class="form-group mt-3">
                    <textarea
                      class="form-control"
                      name="message"
                      rows="8"
                      placeholder="Message"
                      value={msg}
                      onChange={(e) => setmsg(e.target.value)}
                      required
                    ></textarea>
                  </div>
                  <div class="my-3">
                    <div class="loading">Loading</div>
                    <div class="error-message"></div>
                    <div class="sent-message">
                      Your message has been sent. Thank you!
                    </div>
                  </div>

                  <div class="text-center">
                    <div class="row">
                      <div class="col-lg-6">
                        <ReactWhatsapp
                          class="bi bi-whatsapp"
                          type="submit"
                          style={{
                            backgroundColor: "#cda45e",

                            width: 180,
                            height: 45,
                            borderRadius: 25,
                          }}
                          number="216-26-26-4616"
                          onClick={(e) => sendmessage(e)}
                          message={
                            "  client:  " +
                            data.name +
                            "  email:  " +
                            data.email +
                            "  subject:  " +
                            data.subject +
                            "  message:  " +
                            data.msg
                          }
                        />
                      </div>
                      <div class="col-lg-6">
                        <button
                          type="submit"
                          style={{
                            backgroundColor: "#cda45e",
                            alignItems: "center",
                            textAlign: "center",
                            alignContent: "center",
                            width: 180,
                            height: 45,
                            borderRadius: 25,
                          }}
                          onClick={(e) => navigate(-1)}
                        >
                          Retour
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer></Footer>
    </>
  );
}

export default Contact;
