import React, { useEffect, useState, useRef } from "react";
import $ from "jquery";
import { useNavigate } from "react-router-dom";
import { MdClose } from "react-icons/md";
import { FiMenu } from "react-icons/fi";

function HeaderAR() {
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);
  const [selectedValue, setSelectedValue] = useState("");

  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
    if (event.target.value == "AR") {
      // handleInputChangeEntré();
      navigate("/homeAR");
    }
    if (event.target.value == "FR") {
      navigate("/");
    }
    if (event.target.value == "TR") {
      navigate("/homeTR");
    }
  };

  const handleNavCollapse = () => {
    setIsNavCollapsed(!isNavCollapsed);
    changeColor("red");
  };
  const navigate = useNavigate();
  function changeColor(color) {
    document.getElementById("topbar").style.backgroundColor = color;
  }
  return (
    <>
      {/* <header id="header" class="fixed-top d-flex align-items-cente"> */}
      <header id="header" class=" d-flex align-items-center">
        <div class="container-fluid container-xl d-flex align-items-center justify-content-lg-between">
          <table width="100%">
            <tr class="col-lg-12 container position-relative text-center text-lg-start">
              <td>
                {" "}
                <a href="https://www.facebook.com/saraytunis">
                  <i class="bi bi-facebook"></i>
                </a>
              </td>
              <td>
                <span></span>
              </td>
              <td>
                {" "}
                <a href="https://www.instagram.com/saraytunisie/?igshid=MzMyNGUyNmU2YQ%3D%3D">
                  <i class="bi bi-instagram"></i>
                </a>
              </td>
              <td></td>
              <td>
                {" "}
                <a href="https://www.tiktok.com/@saray.turkish.cui">
                  <i class="fa-brands fa-tiktok"></i>
                </a>
              </td>

              <td>
                {" "}
                <a href="https://wa.me/21626264616">
                  <i class="bi bi-whatsapp"></i>
                </a>
              </td>
              <td></td>
              <td>
                <a href="https://www.google.com/maps/place/SARAY+Turkish+Cuisine/@36.8168519,10.1804006,18z/data=!4m16!1m9!4m8!1m3!2m2!1d10.2024269!2d36.8099932!1m3!2m2!1d10.181513!2d36.81704!3m5!1s0x12fd35a32937f761:0x1aa018264134a771!8m2!3d36.8173657!4d10.1818647!16s%2Fg%2F11v9yj2qt1?entry=ttu">
                  <i
                    class="fa fa-map-marker"
                    aria-hidden="true"
                    style={{ color: "rgba(205, 164, 94, 0.7)" }}
                  ></i>
                </a>
              </td>
              <td></td>
              <td>
                {" "}
                <a href="mailto:contact@saray.tn">
                  <i class="bi bi-envelope"></i>
                </a>
              </td>

              <td>
                <a>
                  <i
                    class="bi bi-clock"
                    style={{ color: "rgba(205, 164, 94, 0.7)" }}
                    onClick={(e) => alert("Mardi_Dimanche de 09:00 vers 00:00")}
                  ></i>{" "}
                </a>
              </td>
              <td
                style={{
                  float: "inline-end",
                }}
              >
                <select value={selectedValue} onChange={handleSelectChange}>
                  <option
                    value="FR"
                    style={{
                      fontFamily: "Verdana, Geneva, Tahoma, sans-serif",
                    }}
                  >
                    Lang
                  </option>
                  <option
                    value="FR"
                    style={{
                      fontFamily: "Verdana, Geneva, Tahoma, sans-serif",
                    }}
                  >
                    Fr
                  </option>
                  <option
                    value="AR"
                    style={{
                      fontFamily: "Verdana, Geneva, Tahoma, sans-serif",
                    }}
                  >
                    Ar
                  </option>
                  <option
                    value="TR"
                    style={{
                      fontFamily: "Verdana, Geneva, Tahoma, sans-serif",
                    }}
                  >
                    Tr
                  </option>
                </select>
              </td>
            </tr>
          </table>
        </div>
      </header>
      {/* <header id="header2" class="fixed-top d-flex align-items-cente"> */}
      <header id="header2" class="d-flex align-items-cente">
        <div class="container-fluid container-xl d-flex align-items-center justify-content-lg-between">
          {/* <h1 class="logo me-auto me-lg-0"><a href="index.html">Restaurantly</a></h1> */}
          <h1 class="logo me-auto me-lg-0">
            {" "}
            {/* <img
              src="assets/img/pfeS/CaptureRim.png"
              alt=""
              class="img-fluid"
             // width={110}
              onClick={(e) => navigate("/")}
            /> */}
          </h1>

          <nav id="navbar" class="navbar order-last order-lg-0">
            <ul style={{ color: "#cda45e", float: "right" }}>
              {/*<li>
                <a
                  class="nav-link scrollto active"
                  // href="#hero"
                  onClick={(e) => navigate("/")}
                >
                  {" "}
                  Accueil
                </a>
              </li>
               <li>
                <a
                  class="nav-link scrollto"
                  onClick={(e) => navigate("/about")}
                >
                  Saray
                </a>
              </li> */}
              {/* <li>
                <a
                  class="nav-link scrollto"
                  onClick={(e) => navigate("/about")}
                >
                  Chef
                </a>
              </li> */}
              <li>
                <a
                  class="nav-link scrollto"
                  //  href="#menu"
                  onClick={(e) => navigate("/menuAR")}
                >
                  قائمة الطعام
                </a>
              </li>
              <li>
                <img
                  src="assets/img/pfeS/CaptureRim.png"
                  alt=""
                  class="img-fluid"
                  width={110}
                  style={{ marginLeft: 20 }}
                  onClick={(e) => navigate("/homeAR")}
                />
              </li>

              <li>
                <a
                  class="nav-link scrollto"
                  onClick={(e) => navigate("/galleryAR")}
                >
                  معرض
                </a>
              </li>
            </ul>
            <i
              onClick={(e) => handleNavCollapse()}
              class="bi bi-list mobile-nav-toggle"
            ></i>
          </nav>
          <nav></nav>
          <div
            class={`${isNavCollapsed ? "collapse" : ""} navbar-collapse-arabe`}
            id="navbarsExample09"
            style={{ float: "right" }}
          >
            <div id="topbar" style={{ display: "none", float: "right" }}></div>
            <p
              style={{ right: 2, color: "#cda45e", float: "right" }}
              onClick={(e) => navigate("/homeAR")}
            >
              الصفحة الرئيسية
            </p>

            <p>
              <a
                style={{ left: 2, color: "#cda45e", float: "right" }}
                onClick={(e) => navigate("/menuAR")}
              >
                قائمة الطعام
              </a>
            </p>

            <p>
              <a
                style={{ left: 2, color: "#cda45e", float: "right" }}
                onClick={(e) => navigate("/galleryAR")}
              >
                معرض الصور
              </a>
            </p>

            <p>
              <a
                style={{ left: 2, color: "#cda45e", float: "right" }}
                onClick={(e) => navigate("/reservationAR")}
              >
                الحجز
              </a>
            </p>

            <p>
              <a
                style={{ left: 2, color: "#cda45e", float: "right" }}
                onClick={(e) => navigate("/organisationAR")}
              >
                تنظيم مناسبات
              </a>
            </p>
          </div>
        </div>
      </header>
    </>
  );
}
export default HeaderAR;
