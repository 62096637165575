import logo from "./logo.svg";
import "./App.css";
import Home from "./Home";
import Footer from "./Footer";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Fragment } from "react";
import Header from "./Header";
import Contact from "./Contact";
import Menu from "./Menu";
import About from "./About";
import Gallery from "./Gallery";
import Reservation from "./Reservation";
import HomeTR from "./HomeTR";
import ReservationTR from "./ReservationTR";
import OrganisationTR from "./OrganisationTR";
import Organisation from "./Organisation";
import MenuAR from "./MenuAR";
import Homearabe from "./Homearabe";
import MenuFR from "./MenuFR";
import ReservationAR from "./ReservationAR";
import OrganisationAR from "./OrganisationAR";
import GalleryAR from "./GalleryAR";
import GalleryTR from "./GalleryTR";


function App() {
  return (
    <Fragment>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/footer" element={<Footer />} />
          <Route path="/header" element={<Header />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/menu" element={<Menu />} />
          <Route path="/homeTR" element={<HomeTR />} />
          <Route path="/reservationTR" element={<ReservationTR />} />
          <Route path="/menuFR" element={<MenuFR />} />
          <Route path="/about" element={<About />} />
          <Route path="/galleryTR" element={<GalleryTR />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/galleryAR" element={<GalleryAR />} />

          <Route path="/reservation" element={<Reservation />} />
          <Route path="/organisation" element={<Organisation />} />
          <Route path="/menuAR" element={<MenuAR />} />
          <Route path="/homeAR" element={<Homearabe />} />
          <Route path="/reservationAR" element={<ReservationAR />} />
          <Route path="/organisationAR" element={<OrganisationAR />} />
          <Route path="/organisationTR" element={<OrganisationTR />} />
        </Routes>
      </Router>
    </Fragment>
  );
}

export default App;
